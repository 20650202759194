import * as auth from '../actions/auth.js'
import * as actionTypes from "../constants/action-types";
import _ from 'lodash';
import Cookies from 'js-cookie';

const initialState = {
  rialtoToken: null,
  lmToken: null,
  clientAppToken: null,
  tenentId: null,
  patientId: null,
  scriptId: null,
  orderNumber: null,
  practitionerId: null,
  stripeAccount: null,
  practitionerStripeAccount: null,
  practiceOwnTax: null,
  userType: null,
  puserType: null,
  puserToken: null,
  ppatientId: null,
  ppractitionerId: null,
  subdomain: null,
  userEmail: null,
  loaded: false,
  error: null,
  practitionerName: null,
  practiceAddress: null,
  tenantDiscount: null,
  pureDiscount: null,
  currentUserId: null,
  clientBaseUrl: null,
  ecommerceState: null,
  pureAccountNumber: null,
  browseCatalog: false,
  language: "",
  selfOrder: null,
  isSendScript: false,
  profileId: null,
  IsCartEnabled: true,
  catalogUserDocType:null,
  notProfit:null,
  noProfitAcc:undefined,
  noProfitDiscount:null,
  stripeOnboardComplete: false,
  stripeStatus: "",
  stripeAction: "",
  stripeAccType: "",
  isExpressInitiated: "",
  standardRevoked: null,
  practiceCode:null,
  clientWebUrl:null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case auth.APPTOKEN_SUCCESS:
      localStorage.setItem("railto-token", action.payload.token)
      Cookies.set("railto-token", action.payload.token)
      return {
        ...state,
        rialtoToken: action.payload.token,
        tenentId: action.meta.tenentId,
        userType: action.meta.userType,
        currentUserId: action.payload.user.id,
        practitionerId: action.meta.practitionerId,
        patientId: action.meta.patientId,
        userEmail: action.meta.userEmail,
        loaded: true,
        userToken: action.meta.userToken,
        subdomain: action.meta.subdomain,
        practitionerName: action.meta.practitionerName,
        tenantDiscount: action.payload.tenant.discount,
        pureDiscount: action.payload.tenant.pure_discount,
        clientBaseUrl: action.payload.client_base_url,
        client: action.payload.client,
        ecommerceState: action.payload.tenant.enable_e_commerce,
        pureAccountNumber: action.payload.tenant.pure_account_number,
        browseCatalog: action.meta.browseCatalog,
        parentUrl: action.meta.parentUrl,
        clientAppToken: action.meta.appToken,
        language: action.meta.language,
        selfOrder: action.meta.selfOrder,
        isSendScript: action.meta.isSendScript,
        profileId: action.meta.profileId,
        IsCartEnabled: action.payload.is_cart_enabled,
        catalogUserDocType: action.payload.user_doctype,
        notProfit: action.payload.tenant.is_no_profit,
        noProfitAcc: action.payload.tenant.is_no_profit,
        noProfitDiscount: action.payload.tenant.no_profit_discount,
        practiceCode: action.payload.tenant.tenant_practice_code,
        clientWebUrl: action.payload.client_web_url,
      }
    case auth.APPTOKEN_FAILURE:
      return {
        ...state,
        error: action.error,
        loaded: false,
        stripeAccount: null,
        currentUserId: null,
        clientBaseUrl: null,
        pureAccountNumber: null
      }
    case auth.STRIPE_SUCCESS:
      return {
        ...state,
        stripeAccount: action.payload.content.stripe_account,
        practiceOwnTax: action.payload.content.keep_tax_to_practice
      }
    case auth.STRIPE_FAILURE:
      return {
        ...state,
        error: action.error,
        stripeAccount: null,
        practiceOwnTax: null
      }
    case auth.PURESTRIPE_SUCCESS:
      return {
        ...state,
        stripeAccount: action.payload.content.stripe_account,
        practitionerStripeAccount: action.payload.content.practitioner_stripe_account,
        practiceOwnTax: action.payload.content.keep_tax_to_practice
      }
    case auth.PURESTRIPE_FAILURE:
      return {
        ...state,
        error: action.error,
        stripeAccount: null,
        practitionerStripeAccount: null,
        practiceOwnTax: null
      }
    case auth.CREATE_SCRIPT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        scriptId: action.payload.script_id,
        orderNumber: action.payload.order_number,
        errors: {}
      }
    case auth.CREATE_SCRIPT_FAILURE:
      return {
        ...state,
        isLoading: false,
        scriptId: null,
        orderNumber: null,
        errors: action.payload.response
      }
    case 'PATIENTAPPTOKEN':
      return { ...state, tenentId: action.tenent_id, puserToken: action.user_token, puserType: action.user_type, subdomain: action.subdomain, ppractitionerId: action.practitioner_id, ppatientId: action.patient_id, stripeAccount: action.stripe_account }

    case actionTypes.BROW_CATAL_STATE_CHANGE: 
      return {...state, browseCatalog: true}
    
      case auth.GET_MANAGEMENT_DATA_SUCCESS:
      return {...state, stripeOnboardComplete:  action.payload.stripe_onboard_complete, stripeStatus:  action.payload?.stripe_status, stripeAction:  action.payload?.stripe_action, stripeAccType: action.payload?.account_type, isExpressInitiated: action.payload?.express_initiated, pureAccountNumber: action.payload?.pure_account_number, standardRevoked: action.payload?.standard_revoked }

    case 'CLOSE_ERROR':
      return { ...state, message: null, errors: {}, paymentIntent: undefined }
    default:
      return state
  }
}


export function accessToken(state) {
  if (state.auth) {
    return state.auth.rialtoToken
  }
}

export function isAuthenticated(state) {
  return state.auth && state.auth.rialtoToken
}
